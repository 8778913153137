




























































































































































































































































import truncate from 'vue-truncate-collapsed';
import { Component, Prop, Watch } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import Multiselect from 'vue-multiselect';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import {
  getComponent, getConfigEnv, getView, sleep,
} from '@/utils/helpers';
// import workflowModule from '@/store/modules/workflowModule';
import assetsModule from '@/store/modules/assetsModule';
import { mixins } from 'vue-class-component';
import DataLoading from '@/lib/mixins/dataLoading';
// import { integer } from 'aws-sdk/clients/cloudfront';
import proceduresModule from '@/store/modules/proceduresModule';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import metaDataModule from '@/store/modules/metaDataModule';
import DateRangePicker from 'vue2-daterange-picker';

// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import tasqActionsModule from '@/store/modules/tasqActionsModule';

@Component({
  components: {
    truncate,
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    SetpointFeedbackInfoV2: () => getComponent('tasqs/SetpointFeedbackInfoV2'),
    DefermentLabeling: () => getComponent('tasqs/DefermentLabeling'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
    LineChart: () => import('@/lib/charts/lineChart'),
    FormFieldPreview: () => getView('FormFieldPreview'),
    Multiselect,
    DateRangePicker,
  },
})
export default class TasqFeedbackResponseSection extends mixins(DataLoading) {
    @Prop({ type: Object, required: false }) tasqResponseData?: any;

	activityShown = 'history';

	showingCommentIDs: string[] = []

  actionStepLoading = false;

  loadingActionsDetails =false;

  selectedFinalButton = ''

  	showSetpointWellHistoryDetailsPopup = false

      customSignalRange: any = { startDate: null, endDate: null };

  	setpointDetailsWellHistory = null

  	isSearchingProcedureOptions = false

	isSavingProcedureStep = false

  	searchingProcedureText: string = ''

	mobileSelectedOptions: any = {}

	comment: string = ''

  hidePreviewFieldSection = false;

	actions: any = []

	get actionBuilderActions() {
	  return tasqFeedbackModule.actionBuilderActions;
	}

  getAttachedFile: any = null;

	actionBuilderActionPayload: any = []

	async formsSelectionNextStepHandler() {
	  this.actionStepLoading = true;
	  await sleep(100);
	  if (tasqFeedbackModule.selectedForms && tasqFeedbackModule.selectedForms.length) {
	    this.actionStepLoading = true;
	    if (tasqFeedbackModule.validationResponse.selectionAction === '') {

	    } else {
	      this.$emit('handle-step-action-submit');
	    }

	    await sleep(3000);
	    this.setupActionDetails();
	    await sleep(2000);
	    tasqFeedbackModule.setStepper(3);
	    await sleep(2000);
  	  this.actionStepLoading = false;
	  } else {
	    this.actionStepLoading = false;
	    tasqFeedbackModule.setStepper(2);
	  }
	}

  windowWidth = window.innerWidth;

  get isMobile() {
    return this.windowWidth < 769;
  }

  get selectedActionInputTab() {
    return tasqFeedbackModule.selectedActionInputTab;
  }

  editableInputs: any = []

  setActiveTab(tab) {
    // console.log('setActiveTab');
	  tasqFeedbackModule.setSelectedActionInputTab(tab);
    const action = this.actionBuilderActionPayload.find((a) => a.id === tab);
    tasqFeedbackModule.setSelectedActionDetails(action);
  }

  get selectedActions() {
    // console.log('updates');
	  return tasqFeedbackModule.actionsSelected;
  }

  formIsSelected(option) {
    let formExistIndex = -1;
    if (this.selectedForms && this.selectedForms.length) {
      // @ts-ignore
      formExistIndex = this.selectedForms.findIndex((form) => form.id === option.id);
    }
    if (formExistIndex > -1) {
      return true;
    }

    return false;
  }

  updateFieldValue(payload) {
	 const formIndex = this.actionBuilderActionPayload.findIndex((form) => form.id === payload.formId);
	  if (formIndex > -1) {
	    const fieldIndex = this.actionBuilderActionPayload[formIndex].fields.findIndex((field) => field.id === payload.fieldId);
	    if (fieldIndex > -1) {
	      const formField = JSON.parse(JSON.stringify(this.actionBuilderActionPayload[formIndex].fields[fieldIndex]));
        if (formField && formField.category === 'Dropdown') {
          this.hidePreviewFieldSection = true;
        }
	      formField.localVal = payload.value;
	      this.actionBuilderActionPayload[formIndex].fields[fieldIndex] = formField;
	    }
	  }
    this.$nextTick(() => {
      this.hidePreviewFieldSection = false;
    });
  }

  get selectedForms() {
    return (tasqFeedbackModule.selectedForms);
  }

  async selectFormOption(option, skipOnMobile = false) {
    let formExistIndex = -1;
    if (this.selectedForms && this.selectedForms.length) {
      // @ts-ignore
      formExistIndex = this.selectedForms.findIndex((form) => form.id === option.id);
    }

    if (formExistIndex > -1) {
      tasqFeedbackModule.removeSelectedFrom(formExistIndex);
      // const predictionList: any = (this.tasq.predictionsList);
      // const findCurrentActionTasq = predictionList ? predictionList[option.category] : null;
      // if (findCurrentActionTasq && findCurrentActionTasq.PredictionID) {
      //   await tasqActionsModule.deleteTasq({ id: findCurrentActionTasq.PredictionID });
      // }
    } else {
      tasqFeedbackModule.pushSelectedForm(option);
    }
  }

  compare(a, b) {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  }

  optionGroupsAvailable(optionsGroup) {
    if (this.stepper == 2) {
      const { actions } = optionsGroup;

      //   const systems = JSON.parse(JSON.stringify(optionsGroup.systems));
      return actions;
    } if (this.stepper == 3) {
      return [];
    }
    return [];
  }

  formDetailsLoading = false;

  refreshForm() {
    this.formDetailsLoading = true;
    this.$nextTick(() => {
      this.formDetailsLoading = false;

      this.$forceUpdate();
    });
  }

  get actionsSelectedInputs() {
    return tasqFeedbackModule.actionsSelectedInputs;
  }

  get optionsAvailable() {
    return this.actionBuilderActions;
  }

  get stepper() {
    return tasqFeedbackModule.stepper;
  }

  async created() {
	  // await this.fetchActions();
    this.comment = '';

    //  this.actionBuilderActionPayload = [];
    // console.log('created');
    await this.setupActionDetails();

    this.$eventBus.$on('update-actions', async () => {
      // console.log('update action event');
      if (this.stepper === 3) {
        await this.setupActionDetails(true);
      }
    });
  }

  get dropdownResponseLabel() {
	  if (this.stepper == 1) {
		  return this.identifyingText;
	  } if (tasqFeedbackModule.stepper == 2) {
      return 'What actions are you taking today?';
    } if (tasqFeedbackModule.stepper == 3) {
      return 'Input';
    }
  }

  get identifyingText() {
    // if (this.tasq.completed) return 'This tasq has been closed'
    if ((this.tasq || {}).noComms) return 'Has this been resolved?';
    if ((this.tasq || {}).wellTestData) return 'Has this well test been completed?';
    if (this.tasq.engineerType === this.$getConst('TASQ_WORKFLOW_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_PROLONGED_ANOMALY_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE')
      || this.tasq.predictionType === 'Reject this'
    ) return 'What action was taken?';
    if ((this.tasqResponseData || {}).ResponseData) return 'Please update your feedback:';
    if (this.tasq.engineerType != null && this.tasq.engineerType === this.$getConst('TASQ_STATE_CHANGE_TYPE')) {
      return 'Is there a change in well behavior?';
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_OFF_TARGET_TYPE')) {
      return 'Is this well off target?';
    }
    if (this.tasq.isManuallyAdded) {
      return this.tasq.predictionType;
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_PREDICTION_TYPE')) {
      return 'What would you like to do?';
    }
    return this.tasq.engineerType === 'issue' || !this.tasq
      ? 'Is there an issue here?' : `Did Tasq correctly identify the ${this.tasq?.engineerType}?`;
  }

  // Version of json s3 file
  get jsonVersion() {
    return proceduresModule.proceduresJsonVersion;
  }

  get currentWellType(): any {
    if (assetsModule.activeTasq) {
      return 'producing';
    }
    return this.$route.query.type;
  }

  get tasq() {
    if (tasqsListModule.isBatchResponding) {
      if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (this.currentWellType == 'producing') {
      return assetsModule.activeTasq as TasqJob;
    }
    return tasqsListModule.activeTasq as TasqJob;
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  async setupActionDetails(notUpdateTab = false) {
    this.actionBuilderActionPayload = [];
    this.loadingActionsDetails = true;
    await sleep(1000);
    // console.error(this.stepper);

    // console.log(this.selectedForms);
    if (this.stepper > 1) {
      this.loadingActionsDetails = true;
      const localFormsList: any = [];

      // this.selectedActions =   this.selectedActions.sort()
      this.selectedForms.forEach(async (form, i) => {
        const localFormDetail: any = {};
        Object.assign(localFormDetail, form);
        // localFormDetail.validateForToday = false;
        let fromDetails: any = await metaDataModule.getActionsBuilderRecords({ actionBuilderID: (localFormDetail.id) });

        if (fromDetails.constructor === Array) {
          // eslint-disable-next-line prefer-destructuring
          fromDetails = fromDetails[0];
        }

        localFormDetail.dateOfDisplay = fromDetails.dateOfDisplay;

        if (fromDetails && fromDetails.actionPayload) {
          if (localFormDetail && localFormDetail.fields && localFormDetail.fields.length) {
            localFormDetail.fields = fromDetails.actionPayload.map((field) => {
              const fieldReturnObj = field;
              const localField = localFormDetail.fields.find((responseField) => responseField.id === fieldReturnObj.id);
              if (localField && localField.localVal) {
                fieldReturnObj.localVal = localField.localVal;
              } else {
                fieldReturnObj.localVal = '';
              }
              return fieldReturnObj;
            });
          } else {
            localFormDetail.fields = fromDetails.actionPayload.map((field) => {
              const fieldReturnObj = field;
              fieldReturnObj.localVal = '';
              return fieldReturnObj;
            });
          }
        } else {
          localFormDetail.fields = [];
        }

        // @ts-ignore
        const actionLastResponse: any = this.tasq && this.tasq.predictionsList ? this.tasq.predictionsList[form.category] : null;
        if (actionLastResponse && actionLastResponse.CompletedForToday) {
          localFormDetail.isCompleteForToday = true;
        }

        if (localFormDetail.fields) {
          localFormsList.push(localFormDetail);
        }
      });

      setTimeout(() => {
        this.actionBuilderActionPayload = [];
        const sortedSelectedFormsList = localFormsList.sort((a, b) => a.title.localeCompare(b.title));

        // tasqFeedbackModule.setSeletedForms(sortedSelectedFormsList);
        this.actionBuilderActionPayload = sortedSelectedFormsList;
        if (this.actionBuilderActionPayload && this.actionBuilderActionPayload.length && !notUpdateTab) {
          tasqFeedbackModule.setSelectedActionDetails(this.actionBuilderActionPayload[0]);
          tasqFeedbackModule.setSelectedActionInputTab(this.actionBuilderActionPayload[0].id);
        }

        this.loadingActionsDetails = false;
      }, 4500);
    }
  }

  inputTabButtonText = 'Next'

refreshFilesKey = 0

	filesKeyCount = 1

	noFilesKeyCount = 1

	actionTabStyles(actionID) {
	  const action = this.actionBuilderActionPayload.find((data) => data.id === actionID);
	  if (action && action.validateForToday) {
	    return true;
	  }
	  return false;
	}

	validateFields(fields) {
	  const errors: any = [];
	  fields.forEach((field) => {
	    const errorExist = this.$refs[field.id][0].validateField();
	    if (errorExist) {
	      errors.push(errorExist);
	    }
	  });

	  return errors;

	  //  if (errors && errors.length) {
	  //    console.log('validation failed');
	  //  }
	}

	async submitInput() {
	  const actionDetails = this.actionBuilderActionPayload.find((input) => input.id === this.selectedActionInputTab);
	  if (actionDetails && actionDetails.fields && actionDetails.fields.length) {
	    this.hidePreviewFieldSection = true;
	    this.$nextTick(() => {
	      this.hidePreviewFieldSection = false;
	    });

	    await sleep(100);

	    const errors = this.validateFields(actionDetails.fields);

	    if (errors && errors.length) {
	      return;
	    }
	  }

	  const currentFormIndex = this.actionBuilderActionPayload.findIndex((form) => form.id === actionDetails.id);

	  this.actionBuilderActionPayload[currentFormIndex].validateForToday = true;

	  const nextForm = this.actionBuilderActionPayload.find((form) => form.validateForToday === false);

	  if (nextForm) {
	    tasqFeedbackModule.setSelectedActionInputTab(nextForm.id);
	  } else {
	    tasqFeedbackModule.setSeletedForms(this.actionBuilderActionPayload);
	    tasqFeedbackModule.setStepper(4);
	  }
	}

	backHandler() {
	  tasqFeedbackModule.setStepper(this.stepper - 1);
	}

  // @Watch('selectedForms')
  // async onSelectedFormUpdate() {
  //   await this.setupActionDetails();
  // }
}

